<section #parentRef>
    <div class="image-container">
        <xm-media-image aria-hidden="true" [imageOptions]="icon" class="icon"></xm-media-image>
    </div>
    <ng-container *ngIf="hasFieldOrEditable(rendering.fields.header)">
        <h2 class="header" *scRichText="rendering.fields.header"></h2>
    </ng-container>
    <ng-container *ngIf="hasFieldOrEditable(rendering.fields.title)">
        <div class="title" *scRichText="rendering.fields.title"></div>
    </ng-container>

    <xm-cms-text *ngIf="hasFieldOrEditable(rendering.fields.body)" class="body" [field]="rendering.fields.body"></xm-cms-text>

    <ng-container *ngIf="hasFieldOrEditable(rendering.fields.subTitle)">
        <div class="sub-title" *scRichText="rendering.fields.subTitle"></div>
    </ng-container>

    <ng-container *ngIf="hasFieldOrEditable(rendering.fields.subUnderTitle)">
        <div class="sub-under-title" *scRichText="rendering.fields.subUnderTitle"></div>
    </ng-container>


    <div id="btg" *ngIf="hasFieldOrEditable(rendering.fields.btgPlanSizeOne)" class="data-options-container btgb-container">
        <div class="data-options btg-options"
            *ngIf="hasFieldOrEditable(rendering.fields.btgPlanSizeOne) || hasFieldOrEditable(rendering.fields.btgPlanSizeOneSuffix) || hasFieldOrEditable(rendering.fields.btgPlanPriceOne)">
            <ng-container *ngIf="hasFieldOrEditable(rendering.fields.btgPlanSizeOne)">
                <span class="plan-size" *scRichText="rendering.fields.btgPlanSizeOne"></span>
            </ng-container>
            <ng-container *ngIf="hasFieldOrEditable(rendering.fields.btgPlanSizeOneSuffix)">
                <sup class="suffix" *scRichText="rendering.fields.btgPlanSizeOneSuffix"></sup>
            </ng-container>
            <ng-container *ngIf="hasFieldOrEditable(rendering.fields.btgPlanPriceOne)">
                <div class="plan-price btg-plan" *scRichText="rendering.fields.btgPlanPriceOne"></div>
            </ng-container>
        </div>
        <div class="data-options btg-options"
            *ngIf="hasFieldOrEditable(rendering.fields.btgPlanSizeTwo) || hasFieldOrEditable(rendering.fields.btgPlanSizeTwoSuffix) || hasFieldOrEditable(rendering.fields.btgPlanPriceTwo)">
            <ng-container *ngIf="hasFieldOrEditable(rendering.fields.btgPlanSizeTwo)">
                <span class="plan-size" *scRichText="rendering.fields.btgPlanSizeTwo"></span>
            </ng-container>
            <ng-container *ngIf="hasFieldOrEditable(rendering.fields.btgPlanSizeTwoSuffix)">
                <sup class="header suffix" *scRichText="rendering.fields.btgPlanSizeTwoSuffix"></sup>
            </ng-container>
            <ng-container *ngIf="hasFieldOrEditable(rendering.fields.btgPlanPriceTwo)">
                <div class="plan-price btg-plan" *scRichText="rendering.fields.btgPlanPriceTwo"></div>
            </ng-container>
        </div>
        <div class="data-options btg-options"
            *ngIf="hasFieldOrEditable(rendering.fields.btgPlanSizeThree) || hasFieldOrEditable(rendering.fields.btgPlanSizeThreeSuffix) || hasFieldOrEditable(rendering.fields.btgPlanPriceThree)">
            <ng-container *ngIf="hasFieldOrEditable(rendering.fields.btgPlanSizeThree)">
                <span class="plan-size" *scRichText="rendering.fields.btgPlanSizeThree"></span>
            </ng-container>
            <ng-container *ngIf="hasFieldOrEditable(rendering.fields.btgPlanSizeThreeSuffix)">
                <sup class="header suffix" *scRichText="rendering.fields.btgPlanSizeThreeSuffix"></sup>
            </ng-container>
            <ng-container *ngIf="hasFieldOrEditable(rendering.fields.btgPlanPriceThree)">
                <div class="plan-price btg-plan" *scRichText="rendering.fields.btgPlanPriceThree"></div>
            </ng-container>
        </div>
        <div *ngIf="hasFieldOrEditable(rendering.fields.btgCta)" class="image-container divider"></div>
        <div class="data-options btg-options" *ngIf="hasFieldOrEditable(rendering.fields.btgPlanSizeFour) || hasFieldOrEditable(rendering.fields.btgPlanSizeFourSuffix) || hasFieldOrEditable(rendering.fields.btgPlanPriceFour)">
            <ng-container *ngIf="hasFieldOrEditable(rendering.fields.btgPlanSizeFour)">
                <span class="plan-size" *scRichText="rendering.fields.btgPlanSizeFour"></span>
            </ng-container>
            <ng-container *ngIf="hasFieldOrEditable(rendering.fields.btgPlanSizeFourSuffix)">
                <sup class="header suffix" *scRichText="rendering.fields.btgPlanSizeFourSuffix"></sup>
            </ng-container>
            <ng-container *ngIf="hasFieldOrEditable(rendering.fields.btgPlanPriceFour)">
                <div class="plan-price btg-plan" *scRichText="rendering.fields.btgPlanPriceFour"></div>
            </ng-container>
        </div>
        <div class="data-options btg-options"
            *ngIf="hasFieldOrEditable(rendering.fields.btgPlanSizeFive) || hasFieldOrEditable(rendering.fields.btgPlanSizeFiveSuffix) || hasFieldOrEditable(rendering.fields.btgPlanPriceFive)">
            <ng-container *ngIf="hasFieldOrEditable(rendering.fields.btgPlanSizeFive)">
                <span class="plan-size" *scRichText="rendering.fields.btgPlanSizeFive"></span>
            </ng-container>
            <ng-container *ngIf="hasFieldOrEditable(rendering.fields.btgPlanSizeFiveSuffix)">
                <sup class="header suffix" *scRichText="rendering.fields.btgPlanSizeFiveSuffix"></sup>
            </ng-container>
            <ng-container *ngIf="hasFieldOrEditable(rendering.fields.btgPlanPriceFive)">
                <div class="plan-price btg-plan" *scRichText="rendering.fields.btgPlanPriceFive"></div>
            </ng-container>
        </div>
        <div class="data-options btg-options"
            *ngIf="hasFieldOrEditable(rendering.fields.btgPlanSizeSix) || hasFieldOrEditable(rendering.fields.btgPlanSizeSixSuffix) || hasFieldOrEditable(rendering.fields.btgPlanPriceSix)">
            <ng-container *ngIf="hasFieldOrEditable(rendering.fields.btgPlanSizeSix)">
                <span class="plan-size" *scRichText="rendering.fields.btgPlanSizeSix"></span>
            </ng-container>
            <ng-container *ngIf="hasFieldOrEditable(rendering.fields.btgPlanSizeSixSuffix)">
                <sup class="header suffix" *scRichText="rendering.fields.btgPlanSizeSixSuffix"></sup>
            </ng-container>
            <ng-container *ngIf="hasFieldOrEditable(rendering.fields.btgPlanPriceSix)">
                <div class="plan-price btg-plan" *scRichText="rendering.fields.btgPlanPriceSix"></div>
            </ng-container>
        </div>
    </div>
    <div class="data-options-container">
        <div class="data-options"
            *ngIf="hasFieldOrEditable(rendering.fields.unlimitedPlanSizePrefix) || hasFieldOrEditable(rendering.fields.unlimitedPlanSize) || hasFieldOrEditable(rendering.fields.unlimitedPlanPrice)">
            <ng-container *ngIf="hasFieldOrEditable(rendering.fields.unlimitedPlanSizePrefix)">
                <sup class="header prefix" *scRichText="rendering.fields.unlimitedPlanSizePrefix"></sup>
            </ng-container>
            <ng-container *ngIf="hasFieldOrEditable(rendering.fields.unlimitedPlanSize)">
                <span class="plan-size" *scRichText="rendering.fields.unlimitedPlanSize"></span>
            </ng-container>
            <ng-container *ngIf="hasFieldOrEditable(rendering.fields.unlimitedPlanPrice)">
                <div class="plan-price" *scRichText="rendering.fields.unlimitedPlanPrice"></div>
            </ng-container>
        </div>
    </div>
    <div class="data-options-container">
        <div id="unlimited" class="unlimited-data-options" *ngIf="pageData.headerOne">
            <ng-container *ngIf="pageData.headerOne">
                <div class="plan-card-container plan-card-border">
                    <div class="plan-size-wrapper">
                        <span class="unlimited-plan-size">{{ pageData.headerOne }}</span>
                    </div>
                    <div class="plan-includes">
                        <ul>
                            <li class="included" *ngFor="let inclusion of rendering.fields.unlimitedFeaturesOne; let i = index">
                                <xm-media-image class="icons" [imageOptions]="generateImageFromCms(pageData.unlimitedFeaturesOne[i].icon)">
                                </xm-media-image>
                                <div class="details" *scRichText="inclusion.fields.body"></div>
                            </li>
                        </ul>
                    </div>
                    <div class="plan-price-footer">
                        <span class="plan-price-value">{{ pageData.priceOne }} </span><span>{{ pageData.businessBodyOne }}</span>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="pageData.headerTwo">
                <div class="plan-card-container plan-card-border">
                    <div class="plan-size-wrapper">
                        <span class="unlimited-plan-size">{{ pageData.headerTwo }}</span>
                    </div>
                    <div class="plan-includes">
                        <li class="included" *ngFor="let inclusion of rendering.fields.unlimitedFeaturesTwo; let i = index">
                            <xm-media-image class="icons" [imageOptions]="generateImageFromCms(pageData.unlimitedFeaturesTwo[i].icon)">
                            </xm-media-image>
                            <div class="details" *scRichText="inclusion.fields.body"></div>
                        </li>
                    </div>
                    <div class="plan-price-footer">
                        <span class="plan-price-value">{{ pageData.priceTwo }} </span><span>{{ pageData.businessBodyTwo }}</span>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="pageData.headerThree">
                <div class="plan-card-container">
                    <div class="plan-size-wrapper">
                        <span class="unlimited-plan-size">{{ pageData.headerThree }}</span>
                    </div>
                    <div class="plan-includes">
                        <li class="included" *ngFor="let inclusion of rendering.fields.unlimitedFeaturesThree; let i = index">
                            <xm-media-image class="icons" [imageOptions]="generateImageFromCms(pageData.unlimitedFeaturesThree[i].icon)">
                            </xm-media-image>
                            <div class="details" *scRichText="inclusion.fields.body"></div>
                        </li>
                    </div>
                    <div class="plan-price-footer">
                        <span class="plan-price-value">{{ pageData.priceThree }} </span><span>{{ pageData.businessBodyThree }}</span>
                    </div>
                </div>
            </ng-container>   
        </div>
    </div>
    <div>
        <xm-generic-link class="bottom-link" *ngIf="hasFieldOrEditable(rendering.fields.linkDecorationOne)"
            [field]="rendering.fields.linkDecorationOne"></xm-generic-link>
    </div>
    <xm-cms-text *ngIf="hasFieldOrEditable(rendering.fields.bottomSubtext)" class="bottom-subtext"
        [field]="rendering.fields.bottomSubtext"></xm-cms-text>
    <div *ngIf="hasPlaceholder(rendering, 'unlPricingModal')">
        <div sc-placeholder name="unlPricingModal" [rendering]="rendering"></div>
    </div>
    <xm-popover
    *ngIf="rendering.fields.popover"
    class="popover-button"
    [rendering]="rendering.fields.popover"
    [parentTemplate]="parentRef"
    [customClass]="'planPage'"
    position="center">
</xm-popover>
<xm-generic-link class="btg-cta" *ngIf="hasFieldOrEditable(rendering.fields.btgCta)" [field]="rendering.fields.btgCta"></xm-generic-link>
</section>
