<section class = "{{ theme }}">
    <div class="header">
        <button
            class="popover {{ linkClass }} {{customClass}}"
            [ngClass]="{ 'byod-promo': showByodPromoStyles }"
            *scRichText="rendering.fields.buttonText"
            (click)="viewModal()">
        </button>
    </div>
    <div #innerPopover [ngClass]="{ 'overlay': !inEditMode, 'overlay-inline': inEditMode, 'hide-overlay': hideOverlay }" aria-hidden="true">
        <h2 class="{{ headerClass }}" *scRichText="rendering.fields.header"></h2>
        <div class="content {{ bodyContentClass }}">
            <xm-cms-text class="description" *ngFor="let description of rendering.fields.descriptions" [field]="description.fields.paragraph"></xm-cms-text>
        </div>
    </div>
</section>
