<section #parentRef class="section-container {{sectionContainer}} {{ linkClass }}" [ngClass]="{ 'left-banner': pageData.flipped }">
    <div class="banner-container" [ngClass] ="{'banner-container-first': displayImageBottom, 'banner-container-apple-watch': cmsHeader.header_top }">
        <div class="banner-detail" [ngClass]="{ 'flex': pageData.flipped }">
            <div *ngIf="pageData.borderClass" class="{{pageData.borderClass}}"></div>
            <div *ngIf=!displayImageBottom class="image-container {{imageContainer}}">
                <xm-media-image [imageOptions]="breakpointImages"></xm-media-image>
            </div>
            <div class="text-container" [ngClass]="{'text-container-apple-watch': displayImageBottom }">
                <div class="{{ textAlignment }}">
                    <div class="texts">
                        <ng-container *ngIf="hasFieldOrEditable(rendering.fields.smallTitle)">
                            <div class="small-title" *scRichText="rendering.fields.smallTitle" [ngStyle]="{ color: pageData.smallTitleColor }"></div>
                        </ng-container>
                        <ng-container *ngIf="hasFieldOrEditable(rendering.fields.header)">
                            <div [ngClass]="{ 'header': cmsHeader.default, 'header-your-apple-watch': cmsHeader.header_top, 'header-one': cmsHeader.header_one }" *scRichText="rendering.fields.header" [ngStyle]="{ color: pageData.headerColor }"></div>
                        </ng-container>
                        <xm-cms-text *ngIf="hasFieldOrEditable(rendering.fields.description)" class="description" [ngStyle]="{ color: pageData.descriptionColor }" [field]="rendering.fields.description"></xm-cms-text>
                        <xm-cms-text *ngIf="hasFieldOrEditable(rendering.fields.smallBody)" class="small-body" [ngStyle]="{ color: pageData.smallBodyColor }" [field]="rendering.fields.smallBody"></xm-cms-text>
                        <xm-popover
                        *ngIf="rendering.fields.popover"
                        class="popover-button"
                        [rendering]="rendering.fields.popover"
                        [parentTemplate]="parentRef"
                        [theme]="linkClass"
                        position="center">
                    </xm-popover>
                    </div>
                    <div class="actions button-spacing" *ngIf="hasActions">
                        <xm-generic-link *ngIf="hasFieldOrEditable(rendering.fields.linkDecorationOne)" class="cta" [field]="rendering.fields.linkDecorationOne" [icon]="linkDecorationOneIcon"></xm-generic-link>
                        <xm-generic-link *ngIf="hasFieldOrEditable(rendering.fields.linkDecorationTwo)" class="cta" [field]="rendering.fields.linkDecorationTwo"></xm-generic-link>
                        <xm-cms-text *ngIf="hasFieldOrEditable(rendering.fields.buttonOne)" class="cta" [field]="rendering.fields.buttonOne"></xm-cms-text>
                        <xm-cms-text *ngIf="hasFieldOrEditable(rendering.fields.buttonTwo)" class="cta" [field]="rendering.fields.buttonTwo"></xm-cms-text>
                    </div>
                    <xm-cms-text class="disclaimer-text" [theme]="linkClass" [ngStyle]="{ color: pageData.disclaimerTextColor }" [field]="rendering.fields.disclaimerText"></xm-cms-text>
                </div>
            </div>
            <div *ngIf=displayImageBottom class="image-container {{imageContainer}}">
                <xm-media-image [imageOptions]="breakpointImages"></xm-media-image>
            </div>        
        </div>
        <div *ngIf="pageData.bottomBorderClass" class="{{pageData.bottomBorderClass}}"></div>
    </div>
</section>