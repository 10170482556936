import { AfterViewInit, Component, ElementRef, Injector, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ComponentRendering } from '@sitecore-jss/sitecore-jss';

import { SitecoreComponent } from 'components/shared/sitecore';
import { Modal } from 'core/services/modal';

@Component({
    selector: 'xm-popover',
    templateUrl: 'popover.html',
    styleUrls: [ 'popover.scss' ]
})
export class MarketingPopoverComponent extends SitecoreComponent implements OnInit, AfterViewInit {
    @Input() public rendering: ComponentRendering;
    @Input() public parentTemplate: HTMLElement;
    @Input() public showByodPromoStyles: boolean;
    @Input() public customClass: string = '';
    @Input() public theme: string = '';

    @ViewChild('innerPopover', { static: true }) public childTemplate: ElementRef;

    public pageData: PopoverPageData;
    public hideOverlay: boolean = true;
    public linkClass: string;
    public headerClass: string;
    public bodyContentClass: string;

    private renderer: Renderer2;
    private modal: Modal;

    constructor(injector: Injector, renderer: Renderer2, modal: Modal) {
        super(injector);

        Object.assign(this, { renderer, modal });
    }

    public ngOnInit(): void {
        this.pageData = this.flattenFields<PopoverPageData>(this.rendering.fields);

        this.linkClass = this.showByodPromoStyles ? '' : this.pageData.buttonTheme === 'light' || [ 'light', 'light-grey' ].includes(this.theme) ? 'xm-btn-link-light' : 'xm-btn-link-dark';
        this.headerClass = this.showByodPromoStyles ? '' : this.pageData.headerClass || '';
        this.bodyContentClass = this.showByodPromoStyles ? '' : this.pageData.bodyContentClass || '';
    }

    public ngAfterViewInit(): void {
        if (this.inEditMode && this.parentTemplate && this.childTemplate) {
            const parentElement: HTMLElement = this.renderer.parentNode(this.parentTemplate);
            if (parentElement) {
                this.renderer.appendChild(parentElement, this.childTemplate.nativeElement);
            }
        }
    }

    public viewModal(): void {
        this.modal.descriptive(this.rendering.fields);
    }

}
